import '../css/MainPage.css';
import React from 'react';
import Command from '../components/Command.jsx';


function MainPage() {
  return (
    <Command/>
  );
}

export default MainPage;
