import "../css/LoadingPage.css"


function LoadingPage() {

    return (
        <div class="loading-items">
            <div class="loading2">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default LoadingPage;
