import React from "react";
import { QueryClient, QueryClientProvider } from 'react-query';


const queryClient = new QueryClient();


export const QueryProvider = ({ children }) => (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
);
